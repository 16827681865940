.main-adv{
    display: flex;
    min-width: 100vw;
    height: 100%;
    flex-direction: column;
   
    max-width: 188px;
}
.logo-adv{
    display: flex;
    position: absolute;
    top: 30px;
    left: 30px;
}
.adv-menus{
    flex-direction: column;

}
.adv-fix{
    position: absolute;
    top: 253px;
    left: 30px;
}
.adv-fix #basic-button{
    padding: 0px !important;
    height: 25px;
    border-radius: 5px;
    border: #5d5d5d73 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 0px;
    margin-top: 10px;
    width: 25px;
    font-size: 12px;
    font-family: "ARIAL-Narrow";
}
.adv-word{
    position: absolute;
    top: 105px;
    left: 30px;
}
.adv-jobsu{
    padding:0px !important ;

}
.ram-logo{
    position: fixed;
    bottom: 30px;
    display: flex;
    left: 30px;
}
.form-cont{
    width: 100%;
    overflow-x: hidden;
    height: calc(100vh - 50px);
    left: 0px;
    padding-left: 236px;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 115px;
}
.privacy-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex-direction: column;
    padding-bottom: 40px;
}
.privacy-squ{
    max-width: 360px;
    width: 100%;
    height: 151px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #F7F7F7;
    box-shadow: hsla(0, 0%, 0%, 0.1) 0px 3px 6px;
    border-radius: 10px;
}
.privacy-squ-main{
    max-width: 360px;
    padding-left: 30px;
    padding-right: 40px;
    padding-bottom: 30px;
    overflow-y: scroll;
    width: 100%;
    height: 470px;
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    padding-top: 60px;
    align-items: center;
    justify-content: flex-start;
    background-color: #F7F7F7;
    border-radius: 10px;
}
.privacy-squ-main::-webkit-scrollbar{
    width: 3px;
    border-radius: 5px;
    height: 10px;
    background-color: transparent;
}
.privacy-squ-main::-webkit-scrollbar-thumb{
    width: 3px;
    border-radius: 5px;
    height: 10px;
    background-color: #5D5D5D;
}
.privacy-button{
    max-width: 360px;
    width: 100%;
    height: 69px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.button-ag{
    width: 170px;
    height: 59px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D5FFE8;
    box-shadow: hsla(0, 0%, 0%, 0.1) 0px 3px 6px;

    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    
    font-family: "SF-Pro-Text-light";
    color: #8E8E8E;
}
.button-dec{
    width: 170px;
    height: 59px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background-color: #FFD5D5;
    box-shadow: hsla(0, 0%, 0%, 0.1) 0px 3px 6px;

    border-radius: 10px;
    font-size: 16px;
    font-family: "SF-Pro-Text-light";
    color: #8E8E8E;
}
.form-squ{
    width: 100%;
    max-width: 360px;
    display: flex;
    flex-direction: column;
    margin-top: 23px;
    
}
.input-holder{
    width: 100%;
    position: relative;
    z-index: -9;
    display: flex;
    flex-direction: column;

}
.input-icon{
    position: absolute;
    left: 0px;
    display: flex;
    bottom: 7px;
}
.input-label{
    color: #8E8E8E;
    font-size: 12px;
    display: flex;
    font-family: "SF-Pro-Rounded-Regular";
}
.input-holder input{
    width: 100%;
    padding-top: 5px;
    height: 31px;  
    outline: none;
    background-color: transparent;
    border-bottom: #DDDDDD 1px solid !important;
    border: none;
    padding-left: 20px;
    color: #404040;
    font-size: 14px;
    font-family: "SF-Pro-Rounded";
}
.flag{
    position: absolute;
    left: 20px;
    display: flex;
    bottom: 5px;
}
.close-icon{
    display: flex;
    position: absolute;
    bottom: 7px;
    right: 3px;
}
.poin input{
    cursor: pointer;
}
.input-element-desc{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: 360px;
    flex-direction: row;
}
.input-desc{
    color: #8E8E8E;
    margin-left: 10px;
    font-family: "SF-Pro-Rounded";
    font-size: 10px;
}
.input-desc span{
    color: #5d5d5d;
    font-family: "SF-Pro-Rounded";
    font-size: 10px;
    text-transform: uppercase;
}
.img-item{
    display: flex;
    flex-direction: column;

}
.progress-bars{
    margin-top: 10px;
    width: 100%;
    display: flex;
    height: 5px;
    overflow: hidden;
    background-color: #FFFFFF;
    border-radius: 20px;
    border: #7070708e 1px solid;
}
.progress-in{
    height: 5px;
    display: flex;
    background-color: #82F2B6;
    border-radius: 20px;
    transform: translatey(-1px);
  
}
img{
    cursor: pointer;
}
.apply-button{
    margin-top: 30px;
    width: 100%;
    max-width: 387px;
    background-color: #5674F0;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    box-shadow: hsla(0, 0%, 0%, 0.2) 0px 3px 6px;
    color: #fff;
    font-family: "SF-Pro-Rounded-med";
    font-size: 14px;
    height: 42px;

}
.menu-items{
    width: 100%;
    position: absolute;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 50px;
    left: 0px;
    z-index: 99999999;
    top: 47px;
    background-color: #F4F5F5;
    border-radius: 5px;
    max-height: 500px;
    overflow: auto;
}
.menu-items::-webkit-scrollbar{
    width: 5px;
    background-color: #cecece;
    border-radius: 5px;

}
.menu-items::-webkit-scrollbar-thumb{
    width: 3px;
    background-color: #808080;
    border-radius: 5px;
}
.menu-item{
    display: flex;
    width: 100%;
    max-width: 382px;
    min-height: 33px;
    padding-left: 20px;
    background-color: #DDDDDD;
    border-radius: 5px;
    align-items: center;
    margin-top: 2px;
    justify-content: flex-start;
}
.menu-item.active{
    background-color: transparent;
}
.menu-item:hover{
    background-color: transparent;

}
svg{
    cursor: pointer;
}
.flag-icon{
    display: flex;
}
.flag-text{
    display: flex;
    color: #404040;
    font-family: "SF-Pro-Rounded";
    font-size: 14px;
    margin-left: 10px;
}
.thx-page{
    width: 286px;
    margin-top: 140px;
    height: 121px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

}
.thx-text{
    width: 100%;
    color: #8E8E8E;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 16px;
    font-family: "SF-Pro-Text-light";
}
.thx-row{

    width: 100%;
    display: flex;
    color: #8E8E8E;
    font-size: 16px;
    font-family: "SF-Pro-Text-light";
    align-items: center;
    justify-content: center;

}
.thx-row span{
    font-family: "SF-Pro-Rounded-bold";
}

.done-button{
    width: 100%;
    max-width: 387px;
    position: fixed;
    bottom: 40px;
    cursor: pointer;
    height: 42px;
    background-color: #37EF9A;
    box-shadow: hsla(0, 0%, 0%, 0.2) 0px 3px 6px;
    font-family: "SF-Pro-Rounded-Regular-med";
    font-size: 14px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #404040;
}
@font-face {
    font-family: "SF-Pro-Rounded-Regular-med";
    src: url("../../assets/FontsFree-Net-SF-Pro-Rounded-Medium.ttf");
}
@font-face {
    font-family: "SF-Pro-Rounded-bold";
    src: url("../../assets/FontsFree-Net-SF-Pro-Rounded-Bold.ttf");
}
@font-face {
    font-family: "SF-Pro-Rounded-Regular";
    src: url("../../assets/SF-Pro-Rounded.ttf");
}
@font-face {
    font-family: "SF-Pro-Text-light";
    src: url("../../assets/FontsFree-Net-SF-Pro-Rounded-Light.ttf");
}
.privacy-row{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

}
.privacy-text{
    color: #8E8E8E;
    font-size: 16px;
    font-family: "SF-Pro-Text-light";
    margin-left: 10px;
    letter-spacing: 0.025em;
}
.privacy-text span{
    font-family: "SF-Pro-Rounded-Regular";
}
.logo-mob{
    display: none;
}
@media only screen and (max-width:691px)  {
    .form-squ{
        padding-left: 5px;
        padding-right: 5px;
    }
    .adv-word{
        left: 0px;
        top: 0px;
        background-color: #fff;
        width: 100%;
        height: 205px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 30px;
    }
    .jobsU{
        align-items: flex-start !important;
    }
    .logo-mob{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }
    .logo-adv{
        display: none;
    } 
    .main-adv{
        padding-top: 50px;
    }
    .jobsU{
        margin-top: 0px;
    }
    .adv-menus{
        flex-direction: column;
        display: flex;
        height: 100px;
        justify-content: space-between;
    
    }
    .adv-fix{
        position: absolute;
        top:121px;
        left: initial;
        right: 10px;
    }
    .adv-fix #basic-button{
        padding: 0px !important;
        height: 25px;
        border-radius: 5px;
        margin-right: 10px;
        margin-top: 0px;
        width: 25px;
        font-size: 12px;
        font-family: "ARIAL-Narrow";
    }
    .form-cont{
        padding-top: 175px;
        padding-left: 0px;
        overflow-x: hidden;
        display: flex;
        justify-content: center;

        
    }
    .ram-logo{
        display: none;
    }
}
.arab *{
    letter-spacing: 0.0em !important;
        font-family: 'OoredooArabic' !important;
    
}
.arab #eng{
    font-family: "SF-Pro-Rounded-bold" !important;
}
.form-cont::-webkit-scrollbar{
    width: 0px;
}
#root{
    overflow-x: hidden;
}
.opac{
    opacity: 0.5;
}
@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    20% { transform: translate(-1px, -2px) rotate(-1deg); }
    40% { transform: translate(-3px, 0px) rotate(1deg); }
    60% { transform: translate(3px, 2px) rotate(0deg); }
    80% { transform: translate(1px, -1px) rotate(1deg); }
    100% { transform: translate(-1px, 2px) rotate(-1deg); }
  
  }
.animate{
    animation-name: shake;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1; 
  
  }

  .animate.red-bord:not(svg){
    border-bottom: #ff8c8c 0.5px solid !important;
  }
  .animate.red-bord g g{
    stroke:  #ff8c8c !important;;
  }
  .animate.red-bord g g path{ 
    fill:  #ff8c8c   !important;

  }
  *{
    transition: 0.4s all ease-in;
  }